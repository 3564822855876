import React from "react"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import {
  ContentSurvey,
  ContentSurveyTitle,
  ContentSurveyComment,
  ContentSurveySubmit,
  ContentSurveyDisclaimer,
  ContentSurveySuccess,
} from "@fastly/consistently"

// import FrontmatterPageContext from "~context/FakePageContext"
import T from "~components/translator"

// @TODO move to standardized icons from consistently-icons (once agreement)
import { MdOutlineMood, MdOutlineMoodBad, MdOutlineFeedback } from "react-icons/md"

const objToFormData = (obj, token) => {
  const formData = new FormData()

  Object.entries(obj).forEach(([key, value]) => formData.append(key, value))
  formData.append("g-recaptcha-response", token)
  return formData
}

const FeedbackFormKeep = (props) => {
  const { id } = props
  const { executeRecaptcha } = useGoogleReCaptcha()
  let { pathname } = props

  pathname = pathname.replace(".html", "").replace("/index", "/")

  const form_action = `https://formkeep.com/f/${id}`

  const handleSubmit = async (values) => {
    const token = await executeRecaptcha("feedback_form")
    return fetch(form_action, {
      method: "POST",
      body: objToFormData(values, token),
    })
  }

  const options = [
    {
      value: "yes",
      label: (
        <>
          <MdOutlineMood size="1.5rem" /> <T>feedback-answer-pos</T>
        </>
      ),
    },
    {
      value: "no",
      label: (
        <>
          <MdOutlineMoodBad size="1.5rem" /> <T>feedback-answer-neg</T>
        </>
      ),
    },
    {
      value: "comment_only",
      label: (
        <>
          <MdOutlineFeedback size="1.5rem" /> <T>feedback-answer-com</T>
        </>
      ),
      requiresComment: false,
    },
  ]

  const textarea_label = <T>feedback-do-better</T>

  return (
    <>
      <ContentSurvey onSubmit={handleSubmit} options={options}>
        <ContentSurveyTitle>
          <T>feedback-question</T>
        </ContentSurveyTitle>
        <ContentSurveyComment label={textarea_label} errorMessage="Please fill in the comment area" placeholder="" />
        <ContentSurveySubmit>
          <T>feedback-submit</T>
        </ContentSurveySubmit>
        <ContentSurveyDisclaimer>
          <T>feedback-warning</T>
        </ContentSurveyDisclaimer>
        <ContentSurveySuccess title="Thanks for submitting feedback on our documentation." />
      </ContentSurvey>
    </>
  )
}

export default FeedbackFormKeep
